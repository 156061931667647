<template>
  <div class="posts">
    <!-- 顶部图 -->
    <div class="topImg">
      <el-image :src="detail.img" fit="fill"></el-image>
    </div>
    <div class="info">
      <!-- 标题 -->
      <div class="postTitle">
        <div>{{ detail.title }}</div>
      </div>
      <!-- 作者信息 -->
      <div class="userInfo">
        <div class="avatar">
          <el-image
            :src="user.avatar"
            fit="fill"
            @click="$router.push({ path: `/user/${user.id}` })"
          ></el-image>
        </div>
        <div class="username">
          <div class="name">
            <span>{{ user.username }}</span>

            <el-button
              type="text"
              v-if="!detail.isFollowed"
              @click="createFollowFn(detail.user?.id)"
              >关注</el-button
            >
            <el-button
              type="text"
              v-else
              style="color: var(--white_divFc_light)"
              @click="createFollowFn(detail.user?.id)"
              >已关注</el-button
            >
          </div>
          <div class="time">{{ rTime(detail.created_at) }}</div>
        </div>
        <!-- <div class="readCount"></div> -->
      </div>
    </div>
    <!-- 内容 -->
    <div class="detail">
      <md-editor editorId="md-prev2" :theme="theme" :previewOnly="true" v-model="pDetail.content" />
    </div>
  </div>
</template>

<script>
import { toRefs, watch,ref } from "vue";

import useTheme from "@/hooks/useTheme.js";
import { isLogin, updateLoginShow } from "../../utils/user";
import { ElNotification } from "element-plus";
import { debounce } from "@/utils/public.js";
import { useStore } from "vuex";
import {
  like,
  likeCancel,
  createFollow,
  isFollowed,
  isLikedFn,
} from "@/api/api.js";

export default {
  name: "postsDetail",
  props: ["detail", "user"],
  setup(props, ctx) {
    let store = useStore();
    let rTime = (date) => {
      var json_date = JSON.stringify(new Date(date).toJSON()).replace(
        /-/g,
        "/"
      );
      return json_date
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "")
        .replace(/"/g, "");
    };
    let {detail:pDetail} = toRefs(props)
    console.log(pDetail);
    //关注
    let createFollowFn = (fid) => {
      // 判断是否登陆
      if (!isLogin()) {
        updateLoginShow(true);
        return;
      }
      debounce(() => {
        createFollow({ fid }).then((res) => {
          console.log(res);
          ElNotification({
            type: "success",
            title: "通知",
            message: res.msg,
            position: "bottom-right",
            offset: 50,
          });
          let flag = null;
          if (res.msg == "取消成功！") {
            flag = false;
          } else if (res.msg == "关注成功！") {
            flag = true;
          }
          ctx.emit("updateArticle", "isFollowed", flag);
        });
      }, 500);
    };

    // 监听登陆状态为true的时候获取所有已存在列表的点赞状态
    watch(
      () => store.state.userInfo.isLogin,
       (newValue) => {
        if (newValue) {
          isFollowed({ fid: props.detail.user?.id }).then((res) => {
            ctx.emit("updateArticle", "isFollowed", !!res.data);
          });
        }
      },
      {
        deep: true,
      }
    );

    return {
      pDetail,
      createFollowFn,
      rTime,
      ...useTheme(),
    };
  },
};
</script>

<style>
.detail pre {
  width: 100% !important;
}

.detail p img {
  width: 100% !important;
}

.detail {
  height: auto;
  padding: 15px;
}

.detail p {
  width: 100% !important;
}

.default-theme p {
  padding: 0 !important;
}

.readCount {
  flex: 2;
  height: 60px;
}

.username {
  flex: 6;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  /* border: 1px solid red; */
  box-sizing: border-box;
}

.username .name {
  font-size: 18px;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  box-sizing: border-box;
  height: 55%;
}

.username .time {
  font-size: 14px;
  color: var(--white_divFc_light);
  padding: 0;
  display: flex;
  /* border: 1px solid red; */
  box-sizing: border-box;
  height: 50%;
  align-items: center;
}

.avatar {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
  box-sizing: border-box;
  cursor: pointer;
}

.avatar .el-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.userInfo {
  padding: 10px;
  display: flex;
  color: var(--white_divFc);
}

.postTitle {
  padding: 10px;
  box-sizing: border-box;
  color: var(--white_divFc);
}

.postTitle div{
  font-size: 22px;
  font-weight: 600;
  text-align:justify;
}

.info {
  width: 100%;
  height: auto;
  padding: 0 10px;
  box-sizing: border-box;
}

.posts {
  width: 100%;
  margin: 0 auto;
  height: auto;
  background-color: var(--white_divBgc);
  overflow: hidden;
}
.default-theme {
  background-color: var(--white_divBgc) !important;
  color: var(--white_divFc);
}

.topImg .el-image {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .username {
    margin-left: 8px;
  }
  .detail {
    padding-top: 0 !important;
  }

  .avatar {
    height: 60px;
    width: 60px;
    cursor: none;
  }

  .avatar .el-image {
    width: 50px;
    height: 50px;

    border-radius: 50%;
  }
}
</style>
