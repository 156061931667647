<template>
  <div class="postsMain">
    <!-- 文章内容 -->
    <PostsDetail
      :detail="detail"
      :user="user"
      @updateArticle="updateArticle"
    ></PostsDetail>

    <!-- 评论发布 -->
    <div class="comment">
      <h4>评论</h4>
      <CommentEdit @submitFun="submitFun" :placeholder="placeholder">
        <template #welcome> 欢迎评论，请友好发言！ </template>
      </CommentEdit>
    </div>

    <!-- 评论区 -->
    <div class="replyDiv">
      <!-- 评论列表 -->
      <Reply
        class="Reply"
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        @reply="onReply"
        @spread="onSpread"
        :isComment="true"
        :ref="pushRef"
      >
        <!-- 楼中楼 -->
        <template #reply>
          <div
            class="ReplyItem"
            v-if="replys[comment.id] && replys[comment.id].status"
          >
            <Reply
              class="Reply"
              v-for="(reply, index) in replys[comment.id].data"
              @reply="onReply"
              :comment="reply"
              :key="index"
              :isComment="false"
            >
            </Reply>
          </div>
        </template>
      </Reply>
    </div>
    <div class="loadMore">
      <el-button
        v-if="moreFlag"
        size="small"
        type="default"
        @click="getMoreComments()"
        :loading="moreLoading"
      >
        加载更多
      </el-button>
      <div class="withoutEmpty" v-show="!comments.length">
        -- 期待你的精彩评论 --
      </div>

      <div class="without" v-show="withoutFlag && comments.length">
        -- 就这么多咯 --
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onActivated, onMounted, nextTick, watch, computed } from "vue";
import { postsDetail } from "../../api/api.js";
import { useRoute, useRouter } from "vue-router";
import { useGetters } from "../../store/utils/useGetters.js";
import { useStore } from "vuex";
import useLoading from "../../hooks/useLoading.js";
import useReply from "./useReply.js";
import PostsDetail from "../../components/postsDetail/postsDetail.vue";
import CommentEdit from "../../components/commentEdit/commentEdit.vue";
import Reply from "../../components/reply/reply.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "posts",
  components: {
    PostsDetail,
    CommentEdit,
    Reply,
  },
  setup() {
    let store = useStore();
    let router = useRouter();
    // 获取是否来自评论按钮状态
    let { isFromComment, fromCommentId } = useGetters("postInfo", [
      "isFromComment",
      "fromCommentId",
    ]);
    let { params, meta } = useRoute();
    let route = useRoute();
    let isNotFound = ref(false)
    let useReplys = useReply(params.id, 1);
    let {
      onScrollToElement,
      sleep,
      replyRef,
      scrollReply,
      comments,
      current,
      limit,
    } = { ...useReplys };
    let detail = ref({});
    let user = ref({});

    // 判断是否来自外部评论按钮跳转
    let isFromCommentBtn = async () => {
      console.log(fromCommentId.value);
      // 是的话直接定位到评论区
      if (isFromComment.value && !fromCommentId.value) {
        onScrollToElement(
          "comments",
          async () => {
            let input = document
              .getElementById("comments")
              .getElementsByClassName("textarea-only");
            store.commit("postInfo/UP_isFromComment", false);
            await sleep(300);
            input[0].focus();
          },
          true
        );
      } else if (isFromComment.value && fromCommentId.value) {
        // dom更新后打开新增评论的二级评论区
        scrollReply(
          fromCommentId.value[1],
          fromCommentId.value[0],
          replyRef.data
        );
        store.commit("postInfo/UP_fromCommentId", null);
        store.commit("postInfo/UP_isFromComment", false);
      }
    };

    let updateArticle = (key, flag) => {
      detail.value[key] = flag;
    };

    let loading = useLoading(isFromCommentBtn); //动画

    // 页面挂载
    onMounted(async () => {
      try {
        let { data } = await postsDetail(params.id);
        // store储存文章
        store.commit("postInfo/UP_postsList", { data });
        // 修改store当前文章id
        store.commit("postInfo/UP_postId", params.id);
        detail.value = data;
        user.value = data.user;
      } catch (error) {
        isNotFound.value = true
        router.replace("/404");
      }
    });

    // 组件激活
    onActivated(async () => {
      if(isNotFound.value){
        router.replace("/404");
      }
      window.scrollTo(0, 0);
      store.commit("headInfo/UP_ACTIVE_LINK", "");
      useHead({
        // Can be static or computed
        title: computed(() => (detail.value.title || "") + "_Baymax日常分享"),
        meta: [
          {
            name: `description`,
            content: computed(() => detail.value.shortContent),
          },
          {
            name: `keywords`,
            content: computed(
              () => detail.value.keywords || detail.value.shortContent
            ),
          },
        ],
      });
      // 修改store当前文章id
      store.commit("postInfo/UP_postId", params.id);
      if (Object.keys(detail.value).length > 0 && isFromComment.value) {
        nextTick(() => {
          setTimeout(() => {
            isFromCommentBtn();
          }, 300);
        });
      }
    });

    return {
      ...useReplys,
      detail,
      user,
      updateArticle,
    };
  },
};
</script>

<style scoped lang="scss">
.loadMore {
  padding-left: 10px;
  margin-top: 5px;
  width: 100%;
  box-sizing: border-box;
  min-height: 40px;
  .el-button {
    padding: 8px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}
.withoutEmpty {
  padding: 0 0 20px 0;
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: var(--white_divFc_light);
}
.without {
  padding: 0 0 20px 10px;
  color: var(--white_divFc);
  font-size: 14px;
}
.ReplyItem {
  padding: 0px 15px 15px 15px;
}

.Reply {
  margin-top: 25px;
  border-bottom: 1px solid var(--white_divBoc);
}

.Reply:last-child {
  margin-top: 25px;
  border-bottom: none;
}

.comment {
  padding: 10px;
  background-color: var(--white_divBgc);
}

.comment h4 {
  margin-bottom: 10px;
  color: var(--white_divFc);
}

.replyDiv {
  padding: 10px;
}

.postsMain {
  width: 36%;
  min-width: 500px;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 0 0 8px 8px;
  padding-bottom: 30px;
  height: auto;
  min-width: 600px;
  position: relative;
  z-index: 1;
  background-color: var(--white_divBgc);
}

@media screen and (max-width: 768px) {
  .postsMain {
    width: 100%;
    min-width: 0px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 0 0 8px 8px;
    padding-bottom: 30px;
    height: auto;
    min-width: 300px;
  }
}
</style>
